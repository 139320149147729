@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');
/*==================================================*/
:root{
--color-primary:#00457E;
--color-secondary:#51A0E7;
--color-third:#F25722;
--color-theme-dark:#003764;
--color-theme-light:#DCEEFD;
--color-light-100:#F4FBFF;
--color-light-200:#d6eafa;
--color-white:#ffffff;
--color-dark:#0B0B0B;
--color-gray-100: #f4f5f8;
--color-gray-200: #e3e7ed;
--color-gray-300: #d4d4d7;
--color-gray-400: #e6e6e6;
--color-gray-500: #878B92;
--color-gray-600: #3f3f40;
--color-gray-700: #404041;
--color-gray-800: #3b4863;
--color-gray-900: #1c273c;
--color-gradient01:linear-gradient(180deg, #075FA8 0%, rgba(0, 69, 126, 0.5) 100%);
--color-gradient02: linear-gradient(90deg, #A9FAFF 4.33%, rgba(204, 252, 255, 0.1) 101.23%);
--color-gradient03: linear-gradient(90deg, #8BCAFF 0%, rgba(255, 255, 255, 0.5) 98.27%);
--color-gradient04: linear-gradient(90.11deg, #3E82C1 0.06%, #3C90DB 47.9%, #00457E 99.91%);
--color-gradient05: linear-gradient(90deg, #FFF7EC 0%, #FFFFFF 101.82%);
--color-gradient06: linear-gradient(90deg, rgba(210, 240, 255, 0.1) 0%, rgba(210, 240, 255, 0.4) 51.56%, rgba(210, 240, 255, 0.1) 100%);
--color-gradient07: linear-gradient(2.3deg, #D7F1FF 2.16%, #F0FAFF 61.32%, rgba(255, 255, 255, 0) 98.31%);

--font-primary: "Manrope", sans-serif;
}
/*==================================================*/
body{
font-family: var(--font-primary) !important;
font-size: 0.87rem !important;
line-height: 1.7 !important;
font-weight: 500 !important;
}
a{
text-decoration: none;
}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6{
font-weight: 800 !important;
}
figure{
margin: 0;
}
/*==================================================*/
.bg-gradient07{
background: var(--color-gradient07);
}
.tx-10{
font-size: 10px;
}
.tx-11{
font-size: 11px;
}
.tx-12{
font-size: 12px;
}
.tx-13{
font-size: 13px;
}
.tx-14{
font-size: 14px;
}
.tx-right{
text-align: right;
}
.text-right{
text-align: right;
}
.tx-primary{
color: var(--color-primary);
}
.tx-third{
color: var(--color-third);
}
.tx-gray-500{
color: var(--color-gray-500);
}
.fw600{
font-weight: 600;
}
.fw700{
font-weight: 700;
}
.section-gap-small{
padding: 30px 0px;
}
.section-gap-medium{
padding: 60px 0px;
}
.section-gap-large{
padding: 80px 0px;
}
.section-title{
width: 100%;
position: relative;
margin-bottom: 15px;
display: flex;
align-items: center;
justify-content: space-between;
}
.section-title.center{
justify-content: center;
}
.section-title h2{
font-size: 24px;
}
.viewallbutton{
font-size: 12px;
color: var(--color-primary);
}
.border-right{
border-right: 1px dotted var(--color-gray-300);
}
/*==================================================*/
.header{
width: 100%;
position: relative;
}
.header .header-middle{
width: 100%;
position: relative;
height: 71px;
display: flex;
align-items: center;
background: var(--color-primary);
}
.header .header-bottom{
background: var(--color-gradient02);
width: 100%;
position: relative;
height: 56px;
}
.header .container, .header .container-fluid, .header .inner-wrap {
display: flex;
align-items: center;
}
.header-center, .header-left, .header-right {
display: flex;
align-items: center;
}
.header-left, .header-right {
flex: 1 1;
}
.header .logo img{
width: 120px;
height: auto;
}
.rightlist{
margin: 0;
padding: 0;
list-style: none;
display: flex;
align-items: center;
gap: 20px;
}
.rightlist li{
display: inline-block;
color: var(--color-white);
cursor: pointer;
}
.rightlist li.search-icon{
font-size: 20px;
}
.rightlist li.dlmode{
background: var(--color-theme-dark);
font-size: 15px;
display: flex;
align-items: center;
gap: 10px;
height: 40px;
padding: 0px 15px;
border-radius: 10px;
}
.rightlist li.dlmode i{
font-size: 18px;
}
.sidenav-icon{
color: var(--color-white);
font-size: 20px;
margin-right: 10px;
}
@media screen and (max-width: 991px) {
.rightlist li.search-icon{
font-size: 20px;
}
.rightlist li.dlmode{
font-size: 14px;
height: 35px;
padding: 0px 10px;
}
.rightlist li.dlmode i{
font-size: 16px;
}
}
.header-bottom .hb-box{
background: var(--color-gradient03);
height: 56px;
position: relative;
width: 175px;
display: flex;
align-items: center;
justify-content: center;
color: var(--color-primary);
font-weight: 600;
margin-right: 10px;
}
.header-bottom .hb-box::before {
content: "";
width: 0px;
height: 0px;
border-style: solid;
border-width: 56px 15px 0px 40px;
border-color: transparent #A9FAFF transparent transparent;
position: absolute;
bottom: 0px;
right: 0px;
}
.hb-slider{
flex: 1;
overflow: hidden;
}
.hb-slider .swiper-slide {
width: auto !important;
}
.hb-slider .hb-series{
text-transform: capitalize;
color: var(--color-gray-500);
}
.hb-slider .hb-series:hover{
color: var(--color-primary);
}
@media screen and (max-width: 991px) {
.header .header-bottom{
height: 46px;
}
.header-bottom .hb-box{
width: 105px;
line-height: 16px;
padding: 0px 15px;
height: 46px;
font-size: 12px;
}
.header-bottom .hb-box::before {
border-width: 46px 15px 0px 40px;
}
.hb-slider .hb-series{
font-size: 12px;
}
}
/*==================================================*/
.dropdown-hover:hover>.dropdown-menu {
display: inline-block;
}
.submenu {
border: 0px !important;
background: var(--color-white) !important;
margin: 0 !important;
min-width: 15rem !important;
padding: 0 !important;
border-radius: 0px !important;
}
.submenu .dropdown-item {
display: block;
width: 100%;
padding: 7px 15px;
clear: both;
font-weight: 400;
text-align: inherit;
text-decoration: none;
white-space: nowrap;
background-color: transparent;
border: 0;
font-size: 14px;
}
.submenu .dropdown-item:hover {
background: var(--color-white) !important;
color: var(--color-primary) !important;
}
/*==================================================*/
.nav-link{
color: var(--color-white);
height: 71px;
display: flex;
align-items: center;
padding: 1rem !important;
position: relative;
}
.navbar-nav .nav-link.active{
color: var(--color-white);
background: var(--color-gradient01);
}
.navbar-nav .nav-link.active::after{
content: "";
background: var(--color-secondary);
width: 100%;
height: 4px;
position: absolute;
bottom: 0;
left: 0;
}
.navbar-nav .nav-link:hover{
color: var(--color-white);
background: var(--color-gradient01);
}
.navbar-nav .nav-link:hover::after{
content: "";
background: var(--color-secondary);
width: 100%;
height: 4px;
position: absolute;
bottom: 0;
left: 0;
}
/*==================================================*/
.footer{
padding: 60px 0px;
width: 100%;
position: relative;
background: var(--color-primary);
color: var(--color-white);
}
.footer .footer-title{
font-size: 18px;
margin-bottom: 30px;
}
.footer-logo{
display: inline-block;
}
.footer-logo img{
width: 175px;
height: auto;
}
.footer-list{
margin: 0;
padding: 0;
list-style: none;
}
.footer-list li{
display: inline-block;
width: 100%;
position: relative;
line-height: 32px;
}
.footer-list li a{
display: inline-block;
width: 100%;
position: relative;
color: var(--color-white);
}
.download-button{
display: inline-block;
margin-bottom: 20px;
width: 100%;
}
.download-button img{
width: 185px;
height: auto;
}
.footer-social{
margin: 0;
padding: 0;
list-style: none;
display: flex;
align-items: center;
gap: 10px;
}
.footer-social li{
display: inline-block;
position: relative;
}
.footer-social li a{
width: 45px;
height: 45px;
background: var(--color-dark);
border-radius: 10px;
display: flex;
align-items: center;
justify-content: center;
color: var(--color-white);
}
.footer-social li a i{
font-size: 20px;
}
.copyright{
background: var(--color-primary);
padding: 20px 10px;
text-align: center;
color: var(--color-white);
border-top: 1px solid #063e6b;
}
/*==================================================*/
.subheader{
padding: 20px 0px;
width: 100%;
position: relative;
background: var(--color-primary);
color: var(--color-white);
text-align: center;
}
.subheader .breadcrumb{
margin: 0;
justify-content: center;
}
.subheader .breadcrumb-item a{
color: var(--color-gray-200);
}
.breadcrumb-item + .breadcrumb-item::before{
color: var(--color-gray-200);
}
.breadcrumb-item.active{
color: var(--color-gray-300);
}
.pageSubheader{
padding: 20px 0px;
width: 100%;
position: relative;
}
.pageSubheader .breadcrumb{
margin: 0;
}
.pageSubheader .breadcrumb-item a{
color: var(--color-dark);
font-size: 12px;
}
.pageSubheader .breadcrumb-item + .breadcrumb-item::before{
color: var(--color-dark);
}
.pageSubheader .breadcrumb-item.active{
color: var(--color-primary);
}
/*==================================================*/
.customTabs{
margin: 0;
padding: 0;
list-style: none;
display: flex;
align-items: center;
gap: 10px;
}
.customTabs li{
cursor: pointer;
padding: 12px 30px;
background: var(--color-gray-100);
border: 1px solid var(--color-gray-200);
color: var(--color-gray-500);
border-radius: 10px;
text-align: center;
text-transform: uppercase;
}
.customTabs li.active{
background: var(--color-primary);
color: var(--color-white);
border: 1px solid var(--color-primary);
}
.customTabs li:hover{
background: var(--color-primary);
color: var(--color-white);
border: 1px solid var(--color-primary);
}
@media screen and (max-width: 991px) {
.customTabs li{
padding: 10px 15px;
font-size: 12px;
} 
}
/*==================================================*/
.matchCard{
width: 100%;
position: relative;
border: 1px solid var(--color-gray-400);
margin-top: 20px;
border-radius: 10px;
overflow: hidden;
}
.matchCard .matchCardHeader{
display: flex;
align-items: center;
justify-content: space-between;
height: 32px;
width: 100%;
position: relative;
font-size: 12px;
}
.matchCardHeader .left{
background: var(--color-gradient02);
height: 100%;
position: relative;
width: 75%;
color: var(--color-gray-500);
padding: 0px 10px;
display: flex;
align-items: center;
}
.matchCardHeader .left::before{
content: "";
width: 0px;
height: 0px;
border-style: solid;
border-width: 56px 15px 0px 40px;
border-color: transparent #ffffff transparent transparent;
position: absolute;
bottom: 0px;
right: 0px;
}
.matchCardHeader .seriesname{
overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 1;
-webkit-box-orient: vertical;
}
.matchCardHeader .right{
padding-right: 15px;
}
.matchCardHeader .right span{
display: flex;
align-items: center;
position: relative;
}
.matchCardHeader .right span.live{
color: var(--bs-red);
}
.matchCardHeader .right span.live::after{
content: '';
position: absolute;
top: 40%;
left: -10px;
width: 5px;
height: 5px;
border-radius: 50%;
background: var(--bs-red);
}
.matchCardHeader .right span.upcoming{
color: var(--color-primary);
}
.matchCardHeader .right span.finished{
color: var(--color-dark);
}
.matchCard .matchCardMiddle{
padding: 10px;
width: 100%;
position: relative;
display: flex;
align-items: center;
height: 115px;
}
.matchCardMiddle .matchCardMiddleLeft{
display: flex;
flex-direction: column;
justify-content: center;
width: 60%;
height: 100%;
gap: 10px;
}
.matchTeam{
display: flex;
align-items: center;
gap: 10px;
height: 40px;
}
.matchTeam .matchTeamMedia{
width: 42px;
height: 42px;
border-radius: 50%;
overflow: hidden;
border: 1px solid var(--color-gray-100);
z-index: 9;
}
.matchTeam .matchTeamMedia img{
width: 100%;
height: 100%;
object-fit: cover;
}
.matchTeam .bimg{
width: 48px;
height: 48px;
border-radius: 50%;
overflow: hidden;
position: absolute;
left: -25px;
opacity: 0.1;
}
.matchTeam .matchTeamname{
text-transform: uppercase;
font-size: 14px;
}
.matchCardMiddleRight{
display: flex;
flex-direction: column;
justify-content: center;
width: 40%;
height: 100%;
gap: 10px;
}
.matchTeamScore{
display: flex;
align-items: center;
gap: 5px;
height: 40px;
justify-content: flex-end;
}
.matchTeamScore .score{
font-weight: 700;
}
.matchTeamScore .over{
color: var(--color-gray-500);
}
.matchCardMiddleRight .matchCardMiddleRight-inner{
width: 100%;
height: 100%;
border-left: 1px dotted var(--color-gray-500);
}
.matchCardMiddleRight .matchStatus{
text-align: center;
font-size: 11px;
height: 100%;
display: flex;
align-items: center;
padding-left: 10px;
}
.matchCard .matchCardFooter{
background: var(--color-gradient04);
height: 38px;
width: 100%;
position: relative;
display: flex;
align-items: center;
justify-content: space-between;
padding: 0px 10px;
}
.matchCard .matchCardFooter .left ul{
margin: 0;
padding: 0;
list-style: none;
display: flex;
align-items: center;
gap:5px;
}
.left ul li{
display: flex;
align-items: center;
gap: 1px;
cursor: pointer;
padding:0px 3px 1px 5px;
border: 1px solid var(--color-gray-100);
color: var(--color-white);
font-size: 11px;
border-radius: 5px;
}
.left ul li i{
margin-top: 2px;
}
.matchCard .matchCardFooter .right{
display: flex;
align-items: center;
gap: 5px;
justify-content: flex-end;
}
.matchCard .matchCardFooter .right span{
min-width: 30px;
height: 25px;
border-radius: 5px;
text-align: center;
font-size: 12px;
font-weight: 700;
line-height: 25px;
padding: 0px 5px;
line-height: 25px;
}
.bhv{
min-width: 30px;
height: 25px;
border-radius: 5px;
text-align: center;
font-size: 14px;
font-weight: 700;
padding: 0px 10px;
line-height: 25px;
}
.lay{
background: #F3A0B4;
}
.back{
background: #81C2EC;
}
.name{
background: #ffffff;
}
.session{
background: var(--color-light-200);
}
/*==================================================*/
.cardBox{
width: 100%;
position: relative;
background: var(--color-white);
border: 1px solid var(--color-gray-200);
border-radius: 10px;
}
.cardBox .cardBoxBody{
padding: 15px;
}
/*==================================================*/
.seriesBox{
width: 100%;
position: relative;
display: flex;
align-items: center;
gap: 15px;
}
.seriesBox .seriesBoxMedia{
width: 120px;
height: 135px;
overflow: hidden;
border-radius: 10px;
}
.seriesBox .seriesBoxMedia img{
width: 100%;
height: 100%;
object-fit: cover;
}
.seriesBox .seriesBoxContent{
flex: 1;
}
.seriesBox .title{
font-size: 24px;
}
.seriesBox .seriesBoxMeta{
width: 100%;
position: relative;
margin-bottom: 10px;
}
.seriesBox .seriesBoxMeta ul{
margin: 0;
padding: 0;
list-style: none;
display: flex;
align-items: center;
gap: 20px;
}
.seriesBox .seriesBoxMeta ul li{
color: var(--color-gray-500);
position: relative;
}
.seriesBox .seriesBoxMeta ul li::before{
content: '';
position: absolute;
right: -12px;
top: 11px;
background: var(--color-primary);
border-radius: 50%;
width: 3px;
height: 3px;
}
.seriesBox .seriesBoxMeta ul li:last-child::before{
display: none;
}
.seriesBox .seriesBox-date{
padding: 2px 15px;
border-radius: 7px;
border: 1px solid #B9D7F1;
display: inline-block;
color: var(--color-primary);
background: var(--color-theme-light);
}
.seriesTabs{
width: 100%;
position: relative;
}
.seriesTabs ul{
display: flex;
align-items: center;
gap: 10px;
margin: 0;
padding: 0;
list-style: none;
}
.seriesTabs ul li{
cursor: pointer;
padding: 10px 20px;
border-radius: 10px;
background: var(--color-gray-100);
border: 1px solid var(--color-gray-200);
color: var(--color-gray-500);
}
.seriesTabs ul li.active{
background: var(--color-primary);
border: 1px solid var(--color-primary);
color: var(--color-white);
}
.seriesTabs ul li:hover{
background: var(--color-primary);
border: 1px solid var(--color-primary);
color: var(--color-white);
}
/*==================================================*/
.seriesSidebar{
margin-top: -40px;
}
.playerBox{
width: 100%;
position: relative;
padding: 10px 15px;
display: flex;
align-items: center;
gap: 10px;
border-bottom: 1px solid var(--color-gray-100);
cursor: pointer;
}
.playerBox:last-child{
border-bottom: 0px;
}
.playerBox .playerBoxMedia{
width: 52px;
height: 52px;
border-radius: 50%;
border: 1px solid var(--color-gray-100);
overflow: hidden;
}
.playerBox .playerBoxMedia img{
width: 100%;
height: 100%;
object-fit: cover;
}
.playerBox .playerBoxContent{
flex: 1;
}
.playerBox .playerName{
font-size: 14px;
margin-bottom: 5px;
}
.playerBox .playerStats{
display: inline-block;
}
.playerStats .playerStats-inner{
display: flex;
align-items: center;
border: 1px solid var(--color-gray-200);
border-radius: 5px;
}
.playerStats span{
font-size: 12px;
padding: 0px 10px 1px;
}
.playerStats span.playerteamname{
background: var(--color-gray-100);
font-weight: 800;
}
/*==================================================*/
.player-detailsbox{
width: 100%;
position: relative;
border-radius: 10px;
background: var(--color-gray-100);
padding: 15px;
display: flex;
gap: 15px;
align-items: center;
}
.player-detailsbox .player-detailsbox-media{
width: 125px;
height: 125px;
border-radius: 50%;
overflow: hidden;
border: 2px solid var(--color-white);
}
.player-detailsbox-media img{
width: 100%;
height: 100%;
object-fit: cover;
}
.player-detailsbox .player-name{
font-size: 18px;
}
.pdinfo{
padding: 15px;
width: 100%;
position: relative;
border-bottom: 1px solid var(--color-gray-200);
}
.pdinfo.noborder{
border-bottom: 0px;
}
.pdtable-section{
border: 1px solid var(--color-gray-200);
width: 100%;
position: relative;
overflow: hidden;
border-radius: 7px;
}
.pdtable {
border-collapse: collapse;
width: 100%;
}
.pdtable td, .pdtable th {
border-bottom: 1px solid var(--color-gray-200);
padding: 10px 10px;
font-size: 13px;
}
.pdtable thead{
background:var(--color-gradient06);
}
.pdtable th {
padding: 10px 10px;
color: var(--color-dark);
}
/*==================================================*/
.currentserieslist{
width: 100%;
position: relative;
}
.currentserieslist ul{
margin: 0;
padding: 0;
list-style: none;
}
.currentserieslist ul li{
display: flex;
align-items: center;
justify-content: space-between;
padding:15px;
border-bottom: 1px solid var(--color-gray-100);
cursor: pointer;
}
.currentserieslist ul li:last-child{
border-bottom: 0px;
}
/*==================================================*/
.newsBox{
width: 100%;
position: relative;
padding: 10px 15px;
display: flex;
align-items: center;
gap: 10px;
border-bottom: 1px solid var(--color-gray-100);
cursor: pointer;
}
.newsBox:last-child{
border-bottom: 0px;
}
.newsBox .newsBoxMedia{
width: 70px;
height: 60px;
border-radius: 10px;
border: 1px solid var(--color-gray-100);
overflow: hidden;
}
.newsBox .newsBoxMedia img{
width: 100%;
height: 100%;
object-fit: cover;
}
.newsBox .newsBoxContent{
flex:1
}
.newsBox .title{
font-size: 13px;
margin-bottom: 5px;
overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;
line-height: 19px;
}
.newsBox .newBoxMeta{
margin: 0;
padding: 0;
list-style: none;
display: flex;
align-items: center;
}
.newBoxMeta li{
display: flex;
align-items: center;
font-size: 10px;
color: var(--color-gray-500);
gap: 5px;
}
.rankingtabs{
width: 100%;
position: relative;
border-bottom: 1px solid var(--color-gray-200);
}
.rankingtabs ul{
margin: 0;
padding: 0;
list-style: none;
display: flex;
align-items: center;
justify-content: space-between;
}
.rankingtabs ul li{
font-size: 12px;
padding: 10px 8px;
cursor: pointer;
position: relative;
}
.rankingtabs ul li.active{
color: var(--color-primary);
}
.rankingtabs ul li.active::after{
content: '';
position: absolute;
bottom: 0;
left: 0;
width: 100%;
height: 1px;
background: var(--color-primary);
}
.rankingtabs-inner{
width: 100%;
position: relative;
padding: 10px;
}
.rankingtabs-inner ul{
margin: 0;
padding: 0;
list-style: none;
display: flex;
align-items: center;
justify-content: center;
gap: 10px;
}
.rankingtabs-inner ul li{
cursor: pointer;
font-size: 12px;
padding: 5px 20px;
border-radius: 7px;
background: var(--color-gray-100);
border: 1px solid var(--color-gray-200);
color: var(--color-gray-500);
}
.rankingtabs-inner ul li.active{
background: var(--color-primary);
border: 1px solid var(--color-primary);
color: var(--color-white);
}
.ranktable {
border-collapse: collapse;
width: 100%;
}
.ranktable td, .ranktable th {
border-bottom: 1px solid var(--color-gray-200);
padding: 10px 10px;
font-size: 12px;
color: var(--color-gray-500);
}
.ranktable th {
background-color:var(--color-gray-100);
padding: 5px 10px;
color: var(--color-dark);
}
/*==================================================*/
.newsdetailsbox{
width: 100%;
position: relative;
}
.newsdetailsbox .newsdetailsboxMedia{
width: 100%;
border-radius: 10px;
height: 300px;
overflow: hidden;
position: relative;
}
.newsdetailsboxMedia img{
width: 100%;
height: 100%;
object-fit: cover;
}
.newsdetailsbox .newsdetailtitle{
position: absolute;
bottom: 0;
left: 0;
width: 100%;
padding: 15px;
/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,0.65+100 */
background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
color: var(--color-white);
}
.newsdetailtitle .title{
font-size: 24px;
line-height: 34px;
}
.newsdetailsbox .newsdetailsboxContent{
padding: 15px 0px;
}
/*==================================================*/
.fnewsbox{
width: 100%;
position: relative;
border-radius: 10px;
border: 1px solid var(--color-gray-200);
overflow: hidden;
background: var(--color-white);
}
.fnewsbox .fnewsboxMedia{
width: 100%;
height: 150px;
border-radius: 10px 10px 0px 0px;
overflow: hidden;
}
.fnewsboxMedia img{
width: 100%;
height: 100%;
object-fit: cover;
}
.fnewsbox .fnewsboxContent{
padding: 15px;
}
.fnewsbox .fnewsboxContent .title{
font-size: 14px;
line-height: 22px;
color: var(--color-dark);
}
.fnewsbox .fnewsboxContent .title a{
display: inline-block;
color: var(--color-dark);
}
/*==================================================*/
.hmatchbox{
width: 100%;
position: relative;
height: 70px;
border: 1px solid var(--color-gray-100);
border-radius: 10px;
display: flex;
align-items: center;
padding: 0px 15px;
overflow: hidden;
margin-bottom: 15px;
}
.hmatchbox:last-child{
margin-bottom: 0px;
}
.hmatchbox .hmatchbox-left, .hmatchbox .hmatchbox-right, .hmatchbox .hmatchbox-center {
display: flex;
align-items: center;
}
.hmatchbox-left, .hmatchbox-right {
flex: 1 1;
}
.hmtmatch{
display: flex;
align-items: center;
gap: 10px;
position: relative;
}
.hmtmatch .hmtmatchMedia{
width: 32px;
height: 32px;
border-radius: 50%;
overflow: hidden;
z-index: 9;
}
.hmtmatchMedia img{
width: 100%;
height: 100%;
object-fit: cover;
}
.hmtmatch .hmtmatch-name{
font-size: 14px;
margin-bottom: 1px;
}
.hmtmatch .hmtmatch-score{
display: flex;
align-items: center;
gap: 5px;
}
.hmtmatch-score .score{
font-weight: 700;
}
.hmtmatch-score .over{
color: var(--color-gray-500);
}
.hmtmatch .bimg {
width: 52px;
height: 52px;
border-radius: 50%;
overflow: hidden;
position: absolute;
left: -35px;
opacity: 0.1;
object-fit: cover;
border: 1px solid var(--color-gray-200);
}
.hmtmatch .cimg {
width: 52px;
height: 52px;
border-radius: 50%;
overflow: hidden;
position: absolute;
right: -35px;
opacity: 0.1;
}
/*==================================================*/
.newsbox-big{
width: 100%;
position: relative;
margin-bottom: 15px;
padding-bottom: 15px;
border-bottom: 1px solid var(--color-gray-200);
}
.newsbox-big:last-child{
margin-bottom: 0px;
border-bottom: 0px;
padding-bottom: 0px;
}
.newsbox-big .newsbox-big-media{
width: 100%;
height: 300px;
overflow: hidden;
border-radius: 10px;
}
.newsbox-big .newsbox-big-media img{
width: 100%;
height: 100%;
object-fit: cover;
}
.newsbox-big .newsbox-big-content{
width: 100%;
position: relative;
padding-top: 15px;
}
.newsbox-big .title{
font-size: 18px;
line-height: 28px;
}
.newsbox-big-meta{
margin: 0;
padding: 0;
list-style: none;
display: flex;
align-items: center;
gap:10px;
}
.newsbox-big-meta li {
display: flex;
align-items: center;
font-size: 12px;
color: var(--color-gray-500);
gap: 5px;
}
/*==================================================*/
.modal.left .modal-dialog,
.modal.right .modal-dialog {
position: fixed;
margin: auto;
width: 320px;
height: 100%;
-webkit-transform: translate3d(0%, 0, 0);
-ms-transform: translate3d(0%, 0, 0);
-o-transform: translate3d(0%, 0, 0);
transform: translate3d(0%, 0, 0);
}
.modal.left .modal-content,
.modal.right .modal-content {
height: 100%;
overflow-y: auto;
}
/*Left*/
.modal.left.fade .modal-dialog{
left: -320px;
-webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
-moz-transition: opacity 0.3s linear, left 0.3s ease-out;
-o-transition: opacity 0.3s linear, left 0.3s ease-out;
transition: opacity 0.3s linear, left 0.3s ease-out;
}
.modal.left.fade.show .modal-dialog{
left: 0;
}
/*Right*/
.modal.right.fade .modal-dialog {
right: -320px;
-webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
-moz-transition: opacity 0.3s linear, right 0.3s ease-out;
-o-transition: opacity 0.3s linear, right 0.3s ease-out;
transition: opacity 0.3s linear, right 0.3s ease-out;
}
.modal.right.fade.show .modal-dialog {
right: 0;
}
/*==================================================*/
.menuModal.modal.left .modal-dialog,
.menuModal.modal.right .modal-dialog {
position: fixed;
margin: auto;
width: 320px;
height: 100%;
-webkit-transform: translate3d(0%, 0, 0);
-ms-transform: translate3d(0%, 0, 0);
-o-transform: translate3d(0%, 0, 0);
transform: translate3d(0%, 0, 0);
}
.menuModal.modal.left .modal-content,
.menuModal.modal.right .modal-content {
height: 100%;
overflow-y: auto;
}
/*Left*/
.menuModal.modal.left.fade .modal-dialog{
left: -320px;
-webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
-moz-transition: opacity 0.3s linear, left 0.3s ease-out;
-o-transition: opacity 0.3s linear, left 0.3s ease-out;
transition: opacity 0.3s linear, left 0.3s ease-out;
}
.menuModal.modal.left.fade.show .modal-dialog{
left: 0;
}
.menuModal .modal-content {
background: #fff;
background: var(--color-primary);
border: 0;
border-radius: 0;
}
.menuModal .menuModal-header{
display: flex;
align-items: center;
justify-content: space-between;
padding: 15px;
width: 100%;
position: relative;
border-bottom: 1px solid #053e6d;
}
.menu-logo img{
width: 120px;
}
.menuModal .close-button{
background: transparent;
border: 0px;
padding: 0px;
color: var(--color-white);
}
.menuModal-body{
width: 100%;
position: relative;
padding: 15px;
}
.menu-list{
margin: 0;
padding: 0;
list-style: none;
}
.menu-list li{
display: inline-block !important;
width: 100%;
position: relative;
padding: 10px 0px !important;
border: 0px !important;
font-size: 14px !important;
}
.menu-list li a{
display: flex;
align-items: center;
justify-content: space-between;
color: var(--color-white);
}
/*==================================================*/
.matchDbox{
width: 100%;
position: relative;
}
.matchDbox .match-name{
font-size: 26px;
}
.matchDbox-subtitle{
color: var(--color-gray-500);
}
.matchdetails-section{
width: 100%;
position: relative;
background: var(--color-white);
border: 1px solid var(--color-gray-200);
border-radius: 10px;
overflow: hidden;
}
.upcoming-match{
width: 100%;
position: relative;
padding: 25px;
display: flex;
align-items: center;
justify-content: space-around;
}
.upcoming-match .um-team{
display: flex;
align-items: center;
flex-direction: column;
justify-content: center;
gap: 10px;
}
.um-team .um-team-img{
width: 75px;
height: 75px;
overflow: hidden;
border-radius: 50%;
border: 1px solid var(--color-gray-100);
}
.um-team .um-team-img img{
width: 100%;
height: 100%;
object-fit: cover;
}
.um-team-content{
position: relative;
}
/*==================================================*/
.liveandfinish{
width: 100%;
position: relative;
}
.liveandfinish .liveandfinish-header{
height: 40px;
width: 100%;
position: relative;
background: var(--color-gradient02);
padding: 0px 15px;
}
.liveandfinish-middle{
width: 100%;
position: relative;
display: flex;
padding: 15px;
}
.liveandfinish-middle .liveandfinish-middle-left{
width: 65%;
position: relative;
height: 100%;
padding-right: 15px;
display: flex;
flex-direction: column;
gap: 15px;
border-right: 1px dotted var(--color-gray-500);
}
.liveandfinish-middle .liveandfinish-middle-right{
width: 35%;
position: relative;
height: 100%;
padding-left: 15px;
display: flex;
align-items: center;
justify-content: center;
text-align: center;
}
.liveandfinish .liveScoreResult{
height: 125px;
display: flex;
align-items: center;
justify-content: center;
}
.liveandfinish .liveScoreResult .score{
font-size: 24px;
font-weight: 800;
}
.liveResult{
height: 95px;
display: flex;
align-items: center;
justify-content: center;
}
.livematchteam{
display: flex;
align-items: center;
justify-content: space-between;
width: 100%;
position: relative;
}
.lteamdetails{
display: flex;
align-items: center;
gap: 10px;
height: 40px;
}
.lteamdetails .lteamdetailsMedia {
width: 42px;
height: 42px;
border-radius: 50%;
overflow: hidden;
border: 1px solid var(--color-gray-100);
z-index: 9;
}
.lteamdetails .lteamdetailsMedia img {
width: 100%;
height: 100%;
object-fit: cover;
}
.lteamdetailsimg{
width: 52px;
height: 52px;
border-radius: 50%;
overflow: hidden;
position: absolute;
left: -30px;
opacity: 0.1;
}
.lteamdetails .lteamdetailsname {
text-transform: uppercase;
font-size: 14px;
}
.lscoredetilas {
display: flex;
align-items: center;
gap: 5px;
height: 40px;
justify-content: flex-end;
}
.lscoredetilas .score {
font-weight: 700;
}
.lscoredetilas .over {
color: var(--color-gray-500);
}
.liveandfinish-footer{
height: 45px;
width: 100%;
position: relative;
border-top: 1px dashed var(--color-gray-200);
padding: 0px 15px;
display: flex;
align-items: center;
justify-content: space-between;
}
.currentrunrate{
background: linear-gradient(90deg, #FFF7EC 0%, #FFFFFF 101.82%);
height: 32px;
position: relative;
border-radius: 10px;
display: flex;
align-items: center;
padding: 0px 15px;
color: var(--color-gray-500);
font-size: 13px;
width: 60%;
}
.current-over{
width: 40%;
position: relative;
justify-content: flex-end;
display: flex;
align-items: center;
flex: 1;
}
.current-over ul{
margin: 0;
padding: 0;
list-style: none;
display: flex;
align-items: center;
gap: 10px;
flex-wrap: no-wrap;
overflow: scroll;
}
.current-over ul li{
display: inline-block;
position: relative;
font-size: 13px;
color: var(--color-gray-500);
}
.current-over ul li.over{
width: 24px;
height: 24px;
border-radius: 50%;
background: #DAF1FF;
border-radius: 50%;
display: flex;
align-items: center;
justify-content: center;
font-size: 13px;
line-height: 24px;
color: var(--color-dark);
}
/*==================================================*/
.lmatchTabs{
width: 100%;
position: relative;
}
.lmatchTabs ul{
display: flex;
align-items: center;
gap: 10px;
margin: 0;
padding: 0;
list-style: none;
}
.lmatchTabs ul li{
cursor: pointer;
padding: 10px 15px;
border-radius: 10px;
background: var(--color-gray-100);
border: 1px solid var(--color-gray-200);
color: var(--color-gray-500);
}
.lmatchTabs ul li.active{
background: var(--color-primary);
border: 1px solid var(--color-primary);
color: var(--color-white);
}
.lmatchTabs ul li:hover{
background: var(--color-primary);
border: 1px solid var(--color-primary);
color: var(--color-white);
}
/*==================================================*/
.blog-list{
width: 100%;
position: relative;
background: var(--color-white);
border: 1px solid var(--color-gray-200);
border-radius: 10px;
padding: 15px;
}
.blog-list .blog-list-media{
width: 100%;
height: 250px;
overflow: hidden;
border-radius: 10px;
}
.blog-list .blog-list-media img{
width: 100%;
height: 100%;
object-fit: cover;
-webkit-transition: 0.3s;
transition:0.3s;
}
.blog-list .blog-list-content{
padding-top: 10px;
width: 100%;
position: relative;
}
.blog-list .blog-list-content .title{
font-size: 16px;
line-height: 26px;
}
.blog-list-meta{
margin: 0;
padding: 0;
list-style: none;
display: flex;
align-items: center;
gap:10px;
}
.blog-list-meta li {
display: flex;
align-items: center;
font-size: 12px;
color: var(--color-gray-500);
gap: 5px;
}
.blog-list:hover .blog-list-media img {
-webkit-transform: scale(1.1);
-ms-transform: scale(1.1);
transform: scale(1.1);
}
.blog-list.blog-list-style-1{
display: flex;
gap: 15px;
}
.blog-list.blog-list-style-1 .blog-list-media{
width: 300px;
height: 200px;
}
.blog-list.blog-list-style-1 .blog-list-content{
flex: 1;
padding: 0px;
}
/*==================================================*/
.seriesTabs{
width: 100%;
position: relative;
}
.inner-tabs ul{
display: flex;
align-items: center;
justify-content: center;
gap: 10px;
margin: 0;
padding: 0;
list-style: none;
}
.inner-tabs ul li{
cursor: pointer;
width: 100%;
padding: 15px 20px;
border-radius: 10px;
background: var(--color-gray-100);
border: 1px solid var(--color-gray-200);
color: var(--color-gray-500);
text-align: center;
}
.inner-tabs ul li.active{
background: var(--color-primary);
border: 1px solid var(--color-primary);
color: var(--color-white);
}
.inner-tabs ul li:hover{
background: var(--color-primary);
border: 1px solid var(--color-primary);
color: var(--color-white);
}
/*==================================================*/
.form-group{
width: 100%;
position: relative;
}
.form-group select{
width: 100%;
height: 50px;
border-radius: 10px;
background: var(--color-white);
border: 1px solid var(--color-gray-200);
}
.custom-select {
display: inline-block;
width: 100%;
height: 55px;
padding: 0.56875rem 1.625rem 0.56875rem 0.625rem;
font-size: .87rem;
font-weight: 400;
line-height: 1.5;
vertical-align: middle;
background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%233b4863' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.925rem center/10px 15px !important;
background-color: rgba(0, 0, 0, 0);
background-color: #fff;
border: 1px solid var(--color-gray-200);
border-radius: 10px;
appearance: none;
}
/*==================================================*/
.stable-section{
border: 1px solid var(--color-gray-200);
width: 100%;
position: relative;
overflow: hidden;
border-radius: 7px;
}
.stable {
border-collapse: collapse;
width: 100%;
}
.stable td, .stable th {
border-bottom: 1px solid var(--color-gray-200);
padding: 10px 10px;
font-size: 14px;
color: var(--color-gray-500);
}
.stable thead{
background:var(--color-gradient06);
}
.stable th {
padding: 10px 10px;
color: var(--color-dark);
}
/*==================================================*/
.squad-sidebar{
width: 100%;
position: relative;
background: var(--color-white);
border: 1px solid var(--color-gray-200);
border-radius: 10px;
}
.squadbox{
width: 100%;
position: relative;
display: flex;
align-items: center;
gap: 15px;
padding: 10px;
border-bottom: 1px solid var(--color-gray-200);
cursor: pointer;
}

.squad-sidebar .squadbox:last-child{
border-bottom: 0px;
}
.squadbox .squadboxMedia{
width: 50px;
height: 50px;
border-radius: 50%;
overflow: hidden;
}
.squadbox .squadboxMedia img{
width: 100%;
height: 100%;
object-fit: cover;
}
.squadbox .squadboxContent{
flex: 1;
}
.squadbox .teamName{
font-size: 14px;
margin-bottom: 5px;
}
.squadbox.squadbox-squad{
border: 1px solid var(--color-gray-200);
border-radius: 10px;
background: var(--color-light-100);
}
.squad-setion{
width: 100%;
position: relative;
background: var(--color-white);
border: 1px solid var(--color-gray-200);
border-radius: 10px;
overflow: hidden;
}
.squad-setion .squad-setion-header{
padding: 15px;
width: 100%;
background: var(--color-gradient06);
position: relative;
}
.squad-setion-header .squadTeam{
display: flex;
align-items: center;
gap: 15px;
}
.squadTeam .squadTeam-media{
width: 42px;
height: 42px;
border-radius: 50%;
overflow: hidden;
}
.squadTeam .squadTeam-media img{
width: 100%;
height: 100%;
object-fit: cover;
}
.squadTeam .squadTeam-content{
flex: 1;
}
.squadTeam .teamname{
margin-bottom: 5px;
font-size: 14px;
}
.squad-setion-body{
width: 100%;
padding: 15px;
}
/*==================================================*/
.point-tablebox{
border: 1px solid var(--color-gray-200);
width: 100%;
position: relative;
overflow: hidden;
border-radius: 7px;
}
.point-tablebox-header{
padding: 15px;
}
.ptable {
border-collapse: collapse;
width: 100%;
}
.ptable td, .ptable th {
border-bottom: 1px solid var(--color-gray-200);
padding: 10px 10px;
font-size: 12px;
color: var(--color-gray-500);
}
.ptable thead{
background:var(--color-gradient06);
}
.ptable th {
padding: 5px 10px;
color: var(--color-dark);
}
.ptableTabs{
width: 100%;
position: relative;
}
.ptableTabs ul{
display: flex;
align-items: center;
gap: 10px;
margin: 0;
padding: 0;
list-style: none;
}
.ptableTabs ul li{
cursor: pointer;
padding: 7px 20px;
border-radius: 10px;
background: var(--color-gray-100);
border: 1px solid var(--color-gray-200);
color: var(--color-gray-500);
font-size: 12px;
}
.ptableTabs ul li.active{
background: var(--color-primary);
border: 1px solid var(--color-primary);
color: var(--color-white);
}
.ptableTabs ul li:hover{
background: var(--color-primary);
border: 1px solid var(--color-primary);
color: var(--color-white);
}
/*==================================================*/
.iccrankingbox{
border: 1px solid var(--color-gray-200);
width: 100%;
position: relative;
overflow: hidden;
border-radius: 7px;
}
.iccrankingbox-header{
padding: 15px;
display: flex;
align-items: center;
justify-content: space-between;
}
.iccranktable {
border-collapse: collapse;
width: 100%;
}
.iccranktable td, .iccranktable th {
border-bottom: 1px solid var(--color-gray-200);
padding: 10px 10px;
font-size: 13px;
}
.iccranktable thead{
background:var(--color-gradient06);
}
.iccranktable th {
padding: 5px 10px;
color: var(--color-primary);
}
.iccrankingbox-tabs ul{
display: flex;
align-items: center;
gap: 10px;
margin: 0;
padding: 0;
list-style: none;
}
.iccrankingbox-tabs ul li{
cursor: pointer;
padding: 7px 20px;
border-radius: 7px;
background: var(--color-gray-100);
border: 1px solid var(--color-gray-200);
color: var(--color-gray-500);
font-size: 12px;
}
.iccrankingbox-tabs ul li.active{
background: var(--color-primary);
border: 1px solid var(--color-primary);
color: var(--color-white);
}
.iccrankingbox-tabs ul li:hover{
background: var(--color-primary);
border: 1px solid var(--color-primary);
color: var(--color-white);
}
/*==================================================*/
.icbbox{
display: flex;
align-items: center;
gap: 10px;
}
.icbbox .icbboxMedia{
width: 38px;
height: 38px;
overflow: hidden;
border-radius: 50%;
border: 1px solid var(--color-gray-100);
}
.icbbox .icbboxMedia img{
width: 100%;
height: 100%;
object-fit: cover;
}
.icbbox .icbboxContent{
flex: 1;
}
/*==================================================*/
.list-box{
width: 100%;
position: relative;
}
.list-box ul{
margin: 0;
padding: 0;
list-style: none;
}
.list-box ul li{
display: flex;
align-items: center;
height: 40px;
font-size: 13px;
}
.list-box ul li .lbic{
display: flex;
align-items: center;
gap: 10px;
width: 150px;
}
.list-box ul li .lbic img{
width: 24px;
height: 24px;
}
.list-box ul li span{
flex: 1;
}
/*==================================================*/
.oddsbox{
width: 100%;
position: relative;
background: var(--color-white);
border: 1px solid var(--color-gray-200);
border-radius: 10px;
}
.oddsbox .oddsbox-header{
padding: 10px 15px;
display: flex;
align-items: center;
justify-content: space-between;
cursor: pointer;
}
.oddsbox .oddsbox-header .odleft, .odright{
display: flex;
align-items: center;
gap: 10px;
font-size: 15px;
font-weight: 700;
}
.oddsbox .oddsbox-body{
border-top: 1px solid var(--color-gray-200);
}
.oddinnerbox{
width: 100%;
position: relative;
padding: 15px;
border-bottom: 1px solid var(--color-gray-100);
display: flex;
align-items: center;
justify-content: space-between;
}
.oddinnerbox:last-child{
border-bottom: 0px;
}
.oddsbox .over{
width: 24px;
height: 24px;
border-radius: 50%;
display: flex;
align-items: center;
justify-content: center;
background: var(--color-gray-200);
font-weight: 600;
}
/*==================================================*/
.score-table-section{
border: 1px solid var(--color-gray-200);
width: 100%;
position: relative;
overflow: hidden;
border-radius: 7px;
}
.score-table {
border-collapse: collapse;
width: 100%;
}
.score-table td, .iccranktable th {
border-bottom: 1px solid var(--color-gray-200);
padding: 10px 10px;
font-size: 13px;
}
.score-table thead{
background:var(--color-gradient06);
}
.score-table th {
padding: 10px 10px;
}
/*==================================================*/
.ctabs{
width: 100%;
position: relative;
}
.ctabs ul{
display: flex;
align-items: center;
gap: 10px;
margin: 0;
padding: 0;
list-style: none;
}
.ctabs ul li{
cursor: pointer;
padding: 10px 30px;
border-radius: 10px;
background: var(--color-gray-100);
border: 1px solid var(--color-gray-200);
color: var(--color-gray-500);
font-size: 13px;
}
.ctabs ul li.active{
background: var(--color-primary);
border: 1px solid var(--color-primary);
color: var(--color-white);
}
.ctabs ul li:hover{
background: var(--color-primary);
border: 1px solid var(--color-primary);
color: var(--color-white);
}
/*==================================================*/
.htosbox{
display: flex;
align-items: center;
justify-content: space-between;
}
.htosbox .htosbox-team{
display: flex;
align-items: center;
gap: 10px; 
}
.htosbox .htosbox-team img{
width: 32px;
height: 32px;
border-radius: 50%;
object-fit: cover;
}
.htosbox-result ul{
margin: 0;
padding: 0;
list-style: none;
display: flex;
align-items: center;
gap: 10px;
}
.htosbox-result ul li{
width: 24px;
height: 24px;
border-radius: 50%;
display: flex;
align-items: center;
justify-content: center;
color: var(--color-white);
}
.loose{
background: #DA0100;
}
.win{
background: #4BB728;
}
.star{
background: #D6AE57;
}
/*==================================================*/
.topplayerbox{
width: 100%;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
border-radius: 10px;
border: 1px solid #B6DDFF;
gap: 10px;
text-align: center;
padding: 15px;
background: linear-gradient(166.05deg, rgba(226, 241, 255, 0.8) 8.73%, #FFFFFF 90.66%);
}
.topplayerbox .topplayerbox-media{
width: 62px;
height: 62px;
border: 1px solid var(--color-gray-100);
overflow: hidden;
border-radius: 50%;
}
.topplayerbox .topplayerbox-media img{
width: 100%;
height: 100%;
object-fit: cover;
}
.topplayerbox .topplayerbox-content{
width: 100%;
position: relative;
}
.topplayerbox .player-name{
font-size: 14px;
}
.player-team{
width: 100%;
display: inline-block;
border: 1px solid var(--color-gray-100);
border-radius: 10px;
padding: 10px;
display: flex;
align-items: center;
justify-content: center;
gap: 10px;
color: var(--color-gray-500);
background: var(--color-white);
}
.player-team img{
width: 24px;
height: 24px;
border-radius: 50%;
object-fit: cover;
border: 1px solid var(--color-gray-100);
}
.player-search{
width: 100%;
position: relative;
}
.player-search input{
width: 100%;
height: 55px;
border-radius: 10px;
border: 1px solid var(--color-gray-200);
padding-left: 50px;
}
.player-search .sicon{
position: absolute;
top: 0;
left: 0;
width: 50px;
height: 55px;
display: flex;
align-items: center;
justify-content: center;
font-size: 18px;
color: var(--color-primary);
}
.toogleSec{
display: flex;
align-items: center;
gap: 20px;
}
.toogleSec .tooglebox{
display: flex;
align-items: center;
gap: 10px;
}
.tooglebox.form-switch .form-check-input{
width: 3rem;
height: 1.5em;
}
.player-search-list {
background-color: #fff;
border-radius: 6px;
box-shadow: 0 3px 10px rgb(0 0 0 / 19%);
overflow: auto;
position: absolute;
right: 0;
top: 52px;
-webkit-transition: height .15s ease-in-out;
transition: height .15s ease-in-out;
width: 100%;
z-index: 3;
padding: 10px 0px;
height: 275px;
overflow-y: auto;
overflow-x: hidden;
}
.player-search-list ul {
margin: 0;
padding: 0;
list-style: none;
}
.player-search-list ul li {
-webkit-box-pack: justify;
-ms-flex-pack: justify;
-webkit-box-align: center;
-ms-flex-align: center;
align-items: center;
color: #2E054E;
display: -webkit-box;
display: -ms-flexbox;
display: flex;
font-size: 14px;
justify-content: space-between;
padding: 6px 12px;
text-decoration: none;
cursor: pointer;
}
/*==================================================*/
.mplayerbox{
width: 100%;
position: relative;
background: var(--color-white);
border: 1px solid var(--color-gray-200);
border-radius: 10px;
padding: 15px;
}
.mplayerbox .mplayerbox-inner{
display: flex;
align-items: center;
gap: 15px;
}
.mplayerbox .mplayerbox-media{
width: 62px;
height: 62px;
border-radius: 50%;
overflow: hidden;
border: 1px solid var(--color-gray-100);
}
.mplayerbox-media img{
width: 100%;
height: 100%;
object-fit: cover;
}
.mplayerbox .mplayerbox-content{
flex: 1;
}
.mplayerbox .player-name{
font-size: 16px;
}
.player-meta{
margin: 0;
padding: 0;
list-style: none;
display: flex;
align-items: center;
gap: 15px;
}
.player-meta li{
font-size: 12px;
display: flex;
align-items: center;
gap: 5px;
position: relative;
}
.player-meta li::after{
content: '';
position: absolute;
right: -8px;
top: 6px;
width: 1px;
height: 10px;
background: var(--color-gray-500);
}
.player-meta li:last-child::after{
display: none;
}
.player-meta li img{
width: 20px;
height: 20px;
border-radius: 50%;
border: 1px solid var(--color-gray-100);
}
.mplayerbox-bottom{
margin-top: 10px;
width: 100%;
position: relative;
display: flex;
align-items: center;
gap: 10px;
}
.mplayerbox-bottom span{
width: 100%;
text-align: center;
padding: 5px 10px;
font-size: 12px;
border-radius: 10px;
}
.mplayerbox-bottom .mpodi{
background: #EAF5FF;
border: 1px solid #B6DDFF;
}
.mplayerbox-bottom .t20{
background: #FFFAE9;
border: 1px solid #FFF4CC;
}
.mplayerbox-bottom .test{
background: #FFEDF6;
border: 1px solid #FFE3E8;
}
/*==================================================*/
.fixture-calendar{
border: 1px solid var(--color-gray-200);
border-radius: 10px;
width: 100%;
height: 60px;
display: flex;
align-items: center;
justify-content: space-between;
}
.fixture-calendar .fixture-slider{
width: 80%;
}
.fixture-button-group{
flex: 1;
width: 20%;
justify-content: flex-end;
display: flex;
padding-right: 15px;
}
.calendar-button{
color: var(--color-third);
background: transparent;
border: 0px;
display: flex;
align-items: center;
gap: 15px;
font-weight: 600;
}
/*==================================================*/
.comentrybox{
width: 100%;
position: relative;
}
.comentrybox .comentrybox-header{
padding: 15px;
width: 100%;
position: relative;
}
.comentryTabs{
width: 100%;
position: relative;
}
.comentryTabs ul{
display: flex;
align-items: center;
justify-content: center;
gap: 10px;
margin: 0;
padding: 0;
list-style: none;
}
.comentryTabs ul li{
cursor: pointer;
padding: 7px 20px;
border-radius: 10px;
background: var(--color-white);
border: 1px solid var(--color-gray-200);
color: var(--color-gray-500);
font-size: 13px;
}
.comentryTabs ul li.active{
background: var(--color-primary);
border: 1px solid var(--color-primary);
color: var(--color-white);
}
.comentryTabs ul li:hover{
background: var(--color-primary);
border: 1px solid var(--color-primary);
color: var(--color-white);
}
.comentrybox-header-bottom{
background: linear-gradient(90deg, rgba(210, 240, 255, 0.1) 0%, rgba(210, 240, 255, 0.4) 51.56%, rgba(210, 240, 255, 0.1) 100%);
width: 100%;
position: relative;
display: flex;
align-items: center;
height: 80px;
}
.chblist{
margin: 0;
padding: 0;
list-style: none;
display: flex;
align-items: center;
justify-content: space-between;
width: 100%;
}
.chblist li{
padding: 0px 15px;
position: relative;
width: 100%;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
}
.chblist li::after{
content: "";
width: 1px;
position: absolute;
height: 50px;
right: 0;
top:0;
background: var(--color-gray-200);
}
.chblist li:last-child::after{
display: none;
}
.comentry{
width: 100%;
position: relative;
border-bottom: 1px solid var(--color-gray-200);
padding-bottom: 10px;
margin-bottom: 10px;
display: flex;
gap: 25px;
align-items: flex-start;
}
.comentry:last-child{
border-bottom: 0px;
padding-bottom: 0px;
margin-bottom: 0px;
}
.comentry .comentryovrun{
display: flex;
align-items: center;
gap: 10px;
}
.comentryovrun .ov{
width: 24px;
height: 24px;
border-radius: 50%;
background: #DAF1FF;
border-radius: 50%;
display: flex;
align-items: center;
justify-content: center;
}
.comentry .comentrytext{
flex: 1;
}
/*==================================================*/
.cmoverbox{
width: 100%;
position: relative;
}
.cmoverbox-header{
background: linear-gradient(90deg, rgba(210, 240, 255, 0.1) 0%, rgba(210, 240, 255, 0.4) 51.56%, rgba(210, 240, 255, 0.1) 100%);
width: 100%;
height: 120px;
display: flex;
align-items: center;
padding: 0px 15px;
gap: 25px;
}
.cmoverbox-overbox{
border: 1px solid var(--color-gray-200);
border-radius: 10px;
width: 160px;
height: 70px;
background: var(--color-white);
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
}
.cmoverbox-header .cmoverbox-ovdetails{
flex: 1;
}
.ovlisy{
margin: 0;
padding: 0;
list-style: none;
display: flex;
align-items: center;
gap: 20px;
}
.ovlisy li{
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
}
.ovlisy-over{
width: 28px;
height: 28px;
border-radius: 50%;
background: #DAF1FF;
border-radius: 50%;
display: flex;
align-items: center;
justify-content: center;
font-size: 13px;
line-height: 28px;
}
.ov6{
background: #F401DE;
color: var(--color-white);
}
.ov4{
background: #00AA01;
color: var(--color-white);
}
.ovwd{
background: #B70000;
color: var(--color-white);
}
.ovw{
background: #FF0000;
color: var(--color-white);
}
.ovrun{
background: var(--Black-Heading, #262626);
color: var(--color-white);
}
.ovn{
background: #A020F0;
color: var(--color-white);
}
.cmoverbox-bottom{
width: 100%;
position: relative;
display: flex;
align-items: center;
height: 80px;
}
/*==================================================*/
.winingpos{
padding: 15px;
width: 100%;
position: relative;
display: flex;
align-items: center;
justify-content: space-between;
}
.wininlayb{
padding: 15px;
width: 100%;
position: relative;
display: flex;
align-items: center;
justify-content: space-between;
gap: 10px;
}
.wininlayb span{
border-radius: 5px;
width: 100%;
height: 30px;
text-align: center;
font-size: 14px;
font-weight: 700;
padding: 0px 10px;
line-height: 30px;
}
.wininlayb .run{
background: #3078B8;
color: var(--color-white);
}
.wininlayb .ball{
background: #B70000;

color: var(--color-white);
}
.wininlayb .over{
background: #262626;

color: var(--color-white);
}
/*==================================================*/
/*==================================================*/
/*==================================================*/
/*==================================================*/
/*==================================================*/
/*==================================================*/
/*==================================================*/
/*==================================================*/
/*==================================================*/
/*==================================================*/
/*==================================================*/
/*==================================================*/
/*==================================================*/
/*==================================================*/
/*==================================================*/
/*==================================================*/
/*==================================================*/
/*==================================================*/
/*==================================================*/
/*==================================================*/
/*==================================================*/
/*==================================================*/
/*==================================================*/
:root[data-theme="dark"]{
--color-primary:#0B0B0B;
}
/*==================================================*/
.infinite-scroll-component {
overflow: visible !important;  /* or 'unset' if you prefer */
}